import * as React from 'react';
import classNames from 'clsx';
import {
  formatClassNames,
  getAriaAttributes,
  HAS_CUSTOM_FOCUS_CLASSNAME,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import type {
  ITextAreaInputBaseProps,
  ITextAreaInputImperativeActions,
} from '../TextAreaInput.types';
import semanticClassNames from '../TextAreaInput.semanticClassNames';
import { InlineErrorMessage } from '../../../core/inlineErrorMessage';
import style from './style/TextAreaInput.scss';
import { experimentWrapperElement } from './constants';

const noop = () => {};

const TextAreaInputBase: React.ForwardRefRenderFunction<
  ITextAreaInputImperativeActions,
  ITextAreaInputBaseProps
> = (props, ref) => {
  const {
    skin,
    id,
    className,
    customClassNames = [],
    value = '',
    label,
    placeholder,
    readOnly,
    required,
    isDisabled,
    maxLength,
    isResponsive,
    shouldShowValidityIndication,
    isValid,
    errorMessageType = 'tooltip',
    validateValue = noop,
    onValueChange = noop,
    setValidityIndication = noop,
    onBlur = noop,
    onFocus = noop,
    onKeyPress = noop,
    onInput = noop,
    onChange = noop,
    onClick = noop,
    onDblClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
    ariaAttributes,
    componentViewMode,
    translations,
    keepInputHeightEnabled,
  } = props;

  const [valueChanged, setValueChanged] = React.useState<boolean>();

  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const [isPristine, setIsPristine] = React.useState(true);

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        inputRef.current?.focus();
      },
      blur: () => {
        inputRef.current?.blur();
      },
      setCustomValidity: message => {
        if (message.type === 'message') {
          inputRef.current?.setCustomValidity(message.message);
        }
      },
    };
  });

  const onFirstTouch = () => {
    if (isPristine) {
      setIsPristine(false);
    }
  };

  const validateAndSetValue = (newValue: string) => {
    setValueChanged(true);
    onValueChange(newValue);
    validateValue();
    setValidityIndication(false);
  };

  React.useEffect(() => {
    // Using setTimeout to bypass TB-4995
    setTimeout(() => {
      validateAndSetValue(value); // ECL-8372
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onChange: React.ChangeEventHandler<HTMLTextAreaElement> = event => {
    onFirstTouch();
    validateAndSetValue(event.currentTarget.value);
    event.type = 'input';
    onInput(event);
  };

  const _onBlur: React.FocusEventHandler<HTMLTextAreaElement> = event => {
    setValidityIndication(true);
    onFirstTouch();
    onBlur(event);
    if (valueChanged) {
      onChange({
        ...event,
        type: 'change',
      });
    }
    setValueChanged(false);
  };

  const _onClick: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onClick(event);
    }
  };

  const _onDblClick: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onDblClick(event);
    }
  };

  const _onMouseEnter: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseEnter(event);
    }
  };

  const _onMouseLeave: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseLeave(event);
    }
  };

  const isAriaInvalid = () => {
    if (isValid || isValid === undefined) {
      return !isPristine ? !inputRef.current?.validity.valid : false;
    }
    return true;
  };

  const hasLabel = !!label;

  const containerClasses = classNames(
    style[skin],
    className,
    formatClassNames(semanticClassNames.root, ...customClassNames),
    {
      [style.hasLabel]: hasLabel,
      [style.requiredIndication]: required,
      [style.validationIndication]: !!shouldShowValidityIndication,
    },
    keepInputHeightEnabled ? style.keepInputHeight : null,
  );

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      {...(!keepInputHeightEnabled && { className: containerClasses })}
      onClick={_onClick}
      onDoubleClick={_onDblClick}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
    >
      {React.createElement(
        // eslint-disable-next-line
        // @ts-expect-error
        ...(keepInputHeightEnabled
          ? [
              'div',
              {
                className: containerClasses,
                'data-testid': experimentWrapperElement,
              },
            ]
          : [React.Fragment, {}]),
        <>
          <label
            htmlFor={`textarea_${id}`}
            className={classNames(
              style.label,
              formatClassNames(semanticClassNames.label),
            )}
          >
            {label}
          </label>
          <textarea
            ref={inputRef}
            id={`textarea_${id}`}
            className={classNames(
              style.textarea,
              HAS_CUSTOM_FOCUS_CLASSNAME,
              formatClassNames(semanticClassNames.input),
            )}
            rows={isResponsive ? 1 : undefined}
            value={value}
            onFocus={onFocus}
            onKeyDown={onKeyPress}
            onChange={_onChange}
            onBlur={_onBlur}
            placeholder={placeholder}
            readOnly={readOnly}
            required={required}
            aria-required={required}
            aria-invalid={isAriaInvalid()}
            maxLength={maxLength === null ? undefined : maxLength}
            disabled={isDisabled}
            {...getAriaAttributes(ariaAttributes)}
          ></textarea>
        </>,
      )}
      <InlineErrorMessage
        errorMessageType={errorMessageType}
        errorMessage={inputRef.current?.validationMessage}
        shouldShowValidityIndication={shouldShowValidityIndication}
        translations={translations}
        componentViewMode={componentViewMode}
      />
    </div>
  );
};

export default React.forwardRef(TextAreaInputBase);
